var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _vm.group == null && _vm.error == null
        ? _c(
            "b-row",
            { staticClass: "mt-5" },
            [
              _c(
                "b-col",
                { staticClass: "text-center" },
                [_c("b-spinner", { attrs: { label: "Spinning" } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.group
        ? [
            _c(
              "b-row",
              [
                _c("b-col", [
                  _c("h1", [
                    _vm._v("Rule group: " + _vm._s(_vm.group.Description))
                  ])
                ])
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-card",
                      { staticClass: "mt-4", attrs: { header: "Rules" } },
                      [
                        _c(
                          "b-table-simple",
                          { attrs: { small: "" } },
                          [
                            _c(
                              "b-thead",
                              [
                                _c(
                                  "b-tr",
                                  [
                                    _c("b-th", [_vm._v("First period")]),
                                    _c("b-th", [_vm._v("Last period")]),
                                    _c("b-th", [
                                      _vm._v("Reference / Beneficiary")
                                    ]),
                                    _c("b-th", [_vm._v("Rule Description")]),
                                    _c("b-th", [_vm._v("Share")]),
                                    _c("b-th", [_vm._v("Split")])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-tbody",
                              _vm._l(_vm.group.Members, function(member) {
                                return _c(
                                  "b-tr",
                                  {
                                    key:
                                      member.TransactionReaccountRuleGroupMemberID
                                  },
                                  [
                                    _c("b-td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatAccountingPeriod")(
                                            member.FirstValidAccountingPeriodID
                                          )
                                        )
                                      )
                                    ]),
                                    _c("b-td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatAccountingPeriod")(
                                            member.LastValidAccountingPeriodID
                                          )
                                        )
                                      )
                                    ]),
                                    _c(
                                      "b-td",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name:
                                                  "accounting-statement-spec",
                                                params: {
                                                  id:
                                                    member.Rule
                                                      .StatementReference
                                                }
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  member.Rule.StatementReference
                                                ) +
                                                " - " +
                                                _vm._s(
                                                  member.Rule.StatementSpec
                                                    .Beneficiary.FullName
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-td",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "accounting-rule",
                                                params: {
                                                  id:
                                                    member.Rule
                                                      .TransactionReaccountRuleID
                                                }
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  member.Rule.Description
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("b-td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("percentage")(
                                            member.Rule.ShareRate
                                          )
                                        )
                                      )
                                    ]),
                                    _c("b-td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("percentage")(
                                            member.Rule.SplitRate
                                          )
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-card",
                      { staticClass: "mt-4", attrs: { header: "Matches" } },
                      [
                        _c("trx-match-list", {
                          attrs: { matches: _vm.group.Matches }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }