<template>
  <b-container fluid>
    <b-row v-if="group == null && error == null" class="mt-5">
      <b-col class="text-center">
        <b-spinner label="Spinning"></b-spinner>
      </b-col>
    </b-row>

    <template v-if="group">
      <b-row>
        <b-col>
          <h1>Rule group: {{ group.Description }}</h1>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card header="Rules" class="mt-4">
            <b-table-simple small>
              <b-thead>
                <b-tr>
                  <b-th>First period</b-th>
                  <b-th>Last period</b-th>
                  <b-th>Reference / Beneficiary</b-th>
                  <b-th>Rule Description</b-th>
                  <b-th>Share</b-th>
                  <b-th>Split</b-th>
                  
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="member in group.Members" :key="member.TransactionReaccountRuleGroupMemberID">
                  <b-td>{{ member.FirstValidAccountingPeriodID|formatAccountingPeriod }}</b-td>
                  <b-td>{{ member.LastValidAccountingPeriodID|formatAccountingPeriod }}</b-td>
                  <b-td>
                    <router-link :to="{ name: 'accounting-statement-spec', params: { id: member.Rule.StatementReference } }">
                      {{ member.Rule.StatementReference }} - 
                      {{ member.Rule.StatementSpec.Beneficiary.FullName }}
                    </router-link>
                  </b-td>
                  <b-td>
                    <router-link :to="{ name: 'accounting-rule', params: { id: member.Rule.TransactionReaccountRuleID } }">
                      {{ member.Rule.Description }}
                    </router-link>
                  </b-td>
                  <b-td>{{ member.Rule.ShareRate|percentage }}</b-td>
                  <b-td>{{ member.Rule.SplitRate|percentage }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>

          <b-card header="Matches" class="mt-4">
            <trx-match-list :matches="group.Matches" />
          </b-card>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
  export default {
    name: 'AccountingRuleGroup',
    components: {
      'trx-match-list': () => import('@/components/TrxMatchList'),
    },
    data () {
      return {
        group: null,
        error: null,
      }
    },
    props: {
      id: String
    },
    methods: {
    },
    mounted () {
      this.$http.get('accounting/rule-groups/' + this.id, {
          
        })
        .then(response => {
          this.group = response.data
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            text: 'Er ging iets mis bij het laden'
          });
        });
    }
  }
</script>